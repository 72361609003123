<template>
  <div>
    <ul class="list-unstyled mb-0">
      <li v-for="(news, index) in getNewsEventList" :key="index" class="news-list">
        <a class="news-link" :href="news.publicUrl" v-srOnlyNewWindow target="_blank" v-html="news.pageTitle"></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'NewsWidgetContent',
  computed: {
    getNewsEventList() {
      const limit = this.isExpandedView ? this.data.expViewItemCnt : this.data.dashboardItemCnt
      return this.data.newsResult.response.slice(0, limit)
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
